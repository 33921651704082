<template>
  <div id="home">
    <home-navbar></home-navbar>
    <div class="grey-wrapper">
      <div id="how-to-use" class="container">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <h1 class="headline">
              <span class="font-weight-bold">デジタルステージング</span><br>
            </h1>
            <h3 class="font-size-h3">
              より早く！より高く！物件を決める術
            </h3>
            <p class="feature-box">
              <font-awesome-icon
                  :icon="{ prefix: 'fa-solid', iconName: 'circle-check'}"
                  class="feature-icon"
              /><span class="font-weight-bold">スマホで完結する簡単オーダー</span><br>
              <font-awesome-icon
                  :icon="{ prefix: 'fa-solid', iconName: 'circle-check' }"
                  class="feature-icon"
              /><span class="font-weight-bold">1枚4,950円48時間納品</span><br>
              <font-awesome-icon
                  :icon="{ prefix: 'fa-solid', iconName: 'circle-check' }"
                  class="feature-icon"
              /><span class="font-weight-bold">ハイクオリティな画質</span>
            </p>
            <p class="mt-3 grey-text text-justify sub-headline">
                米国の統計によると、ほとんどの人はインターネットで物件 広告を見てから10秒以内にその物件の良し悪しを判断します。<br>
              Most people make a judgement on a property
              within 7-10 seconds of viewing it.
            </p>
            <button
              class="btn mt-3 white-text btn-how-to-use"
              type="button"
              @click="goto('how-it-works')"
            >
                使い方の説明はこちら
            </button>
            <p class="mb-5 mt-2 text-getting-started">
              <router-link :to="{ name: 'anonymousRoute-login' }"
                >こちらをクリック</router-link
              >して利用を開始してください
            </p>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <img src="/img/home/how-to-use.png" alt="how-to-use" />
          </div>
        </div>
      </div>
    </div>
    <div class="grey-wrapper">
      <div id="our-characteristics" ref="our-characteristics">
        <div class="container">
          <div class="new-account">
            <button class="account-button" type="button">
              <router-link class="routerLink" :to="{ name: 'anonymousRoute-register' }">
                新規アカウント作成はこちら
              </router-link>
            </button>
          </div>
          <h3 class="blue-text text-center font-weight-bold mb-5">
            デジタルステージングの<br>3つの特徴
          </h3>
          <div class="row mt-5">
            <div class="col-12 col-md-12 col-lg-4 col-xl-4 mb-4 text-center">
              <img
                src="/img/home/feature01.png"
                alt="our-characteristics-1"
              />
            </div>
            <div class="col-12 col-md-12 col-lg-4 col-xl-4 mb-4 text-center">
              <img
                src="/img/home/feature02.png"
                alt="our-characteristics-1"
              />
              <span class="description">※クレジット、または請求書払いが可能です。</span>
            </div>
            <div class="col-12 col-md-12 col-lg-4 col-xl-4 mb-4 text-center">
              <img
                src="/img/home/feature03.png"
                alt="our-characteristics-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="how-it-works">
      <div class="container">
        <h3 class="blue-text text-center font-weight-bold mb-5 mt-5">
          使い方のご説明
        </h3>
          <hr>
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <h5 class="headline">
                      <span class="font-weight-bold primary-border">RealtyBankがお届けする<br>デジタルステージングサービスとは？</span><br>
                  </h5>
                  <div class="col-12 col-md-12" style="margin-bottom: 20px;">
                      <img
                          src="/img/digital-staging-logo.svg"
                          alt="Digital Staging"
                          style="padding: 20px;"
                      />
                  </div>
                  <p class="feature-box">
                      <font-awesome-icon
                          :icon="{ prefix: 'fa-solid', iconName: 'circle-check'}"
                          class="feature-icon"
                      /><span class="font-weight-bold">スマホで完結する簡単オーダー</span><br>
                      <font-awesome-icon
                          :icon="{ prefix: 'fa-solid', iconName: 'circle-check' }"
                          class="feature-icon"
                      /><span class="font-weight-bold">1枚4,950円48時間納品</span><br>
                      <font-awesome-icon
                          :icon="{ prefix: 'fa-solid', iconName: 'circle-check' }"
                          class="feature-icon"
                      /><span class="font-weight-bold">ハイクオリティな画質</span>
                  </p>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img src="/img/home/usage-process.png" class="object-fit-cover"/>
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <h5 class="headline">
                      <span class="font-weight-bold primary-border">デジタルステージングの利用手順</span><br>
                  </h5>
                  <div class="col-md-10 offset-md-1">
                      <div>
                          <dl class="mb0">
                              <dt><span class="label label-primary" style="font-size: 100%;margin-right: 10px;">STEP1</span></dt>
                              <dd style="margin-left: 5em;">
                                  <span style="font-size: 100%;">デザインスタイルの選択<br>(8パターンを用意)</span>
                              </dd>
                          </dl>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img class="object-fit-cover" src="/img/home/step00.png" alt="how-to-use" />
              </div>
          </div>
          <hr style="width: 95%;">
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <div class="col-md-10 offset-md-1">
                      <div>
                          <p>
                              <span class="label label-primary" style="font-size: 100%;margin-right: 10px;">STEP2</span>
                              <span style="font-size: 100%;">画像のアップロード</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img src="/img/home/step01.png" alt="how-to-use" />
              </div>
          </div>
          <hr style="width: 95%;">
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <div class="col-md-10 offset-md-1">
                      <div>
                          <p>
                              <span class="label label-primary stepLabel">STEP3</span>
                              <span class="stepFontSize">アップロードした部屋を選択</span>
                          </p>
                          <p>
                              <span class="label label-primary stepLabel">STEP4</span>
                              <span class="stepFontSize">設置する家具の選択</span>
                          </p>
                          <p>
                              <span class="label label-primary stepLabel">STEP5</span>
                              <span class="stepFontSize">追加オプションを選択</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img src="/img/home/step02.png" alt="how-to-use" />
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <div class="col-md-10 offset-md-1">
                      <div class="displayTableCell verticalAlignMiddle">
                          <p>
                              <span class="label label-primary stepLabel">STEP6</span>
                              <span class="stepFontSize">注文内容の確認・決済</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img src="/img/home/step03.png" alt="how-to-use" />
              </div>
          </div>
          <hr style="width: 95%;">
          <div class="row">
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                  <div class="col-md-10 offset-md-1">
                      <div>
                          <p>
                              <span class="label label-primary stepLabel">STEP7</span>
                              <span class="stepFontSize">弊社にて編集</span>
                          </p>
                          <p>
                              <span class="label label-primary stepLabel">STEP8</span>
                              <span class="stepFontSize">依頼者による編集写真の確認</span>
                          </p>
                          <p>
                              <span class="label label-primary stepLabel">STEP9</span>
                              <span class="stepFontSize">納品確定</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                  <img src="/img/home/step04.png" alt="how-to-use" />
              </div>
          </div>
      </div>
    </div>
      <div>
          <div class="container">
              <div class="row mb-5">
                  <div class="col-12 col-md-12 col-lg-7 col-xl-7 mb-4">
                      <h5 class="headline">
                          <span class="font-weight-bold primary-border">米国での驚くべき<br>デジタルステージングに関する統計</span><br>
                      </h5>
                  </div>
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                      <dl class="mb0">
                          <dt class="color-primary">❶</dt>
                          <dd>
                              <p class="mb0 color-primary custom-font-size">2018年のホームステージングに関する住宅調査では、 68%の住宅が9%高い金額で売れました。</p>
                          </dd>
                      </dl>
                      <p>Virtual staging can help rent homes and units for more money. In a 2018 home survey about home staging, 68% of homes sold for 9% more money. Considering that statistic and that virtual staging also saves money, the return on investment is much higher than traditional staging.</p>
                      <p>バーチャルステージングは、より高い金額で賃貸、売買するのに役立ちます。 2018年のホームステージングに関する住宅調査では、68%の住宅が9%高い金額で売れたそ うです。その統計が、安価なバーチャルステージングでお金の節約になることを考えると、 投資対効果は従来のステージングよりはるかに高いです。</p>
                  </div>
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                      <dl class="mb0">
                          <dt class="color-primary">❷</dt>
                          <dd>
                              <p class="mb0 color-primary custom-font-size">81%の見込み客がステージングされた物件は 将来の住まいとしてイメージしやすいと感じる。</p>
                          </dd>
                      </dl>
                      <p>81% of prospects find it easier to visualize the property as their future home when it is staged. According to the National Association of Realtors, 81% of buyers are able to better visualize a space as their next home when it is staged.</p>
                      <p>全米不動産協会によると、81%のバイヤーが、ステージングされた空間は、次の住まいとし てよりイメージしやすいと回答しています。</p>
                  </div>
              </div>
              <div class="row mb-5">
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                      <dl class="mb0">
                          <dt class="color-primary">❸</dt>
                          <dd>
                              <p class="mb0 color-primary custom-font-size">ステージングされた物件はステージングされていない物件よりも 73%早く賃貸/販売されます</p>
                          </dd>
                      </dl>
                      <p>Staged properties rent/sell faster than unstaged properties. According to the Mortgage Report, staged photographs on listing websites receive more views and thus rent/sell 73% faster than unstaged competition.And it's not hard to see why: prospects enjoy seeing how their new home could look, rather than viewing an empty shell.</p>
                      <p>Mortgage Reportによると、リスティングサイトのステージングされた写真は、ステージン グされていない競合他社よりも閲覧数が多く、その結果、73%早く賃貸/販売することがで きます。その理由は簡単で、見込み客は、空のシェル(殻)を見るよりも、新しい家がどの ように見えるかを楽しむからです。</p>
                  </div>
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                      <dl class="mb0">
                          <dt class="color-primary">❹</dt>
                          <dd>
                              <p class="mb0 color-primary custom-font-size">統計では、ほとんどの人は物件を見てから 7~10秒以内にその物件を判断します。</p>
                          </dd>
                      </dl>
                      <p>Most people make a judgement on a property within 7-10 seconds of viewing it. Lastly, and probably one of the most important statistics: property owners only have seven seconds to make a great first impression. Empty images won't make as great of a first impression as staged images on listings.</p>
                      <p>ほとんどの人は、物件を見てから7~10秒以内にその物件を判断します。最後に、おそらく 最も重要な統計の一つですが、不動産を検討する時に、7秒の間に素晴らしい第一印象を与 えることができます。空っぽの画像は、リスティング広告で演出された画像ほど素晴らしい 第一印象を与えることはありません。</p>
                  </div>
              </div>
          </div>
      </div>

    <home-footer></home-footer>
  </div>
</template>

<script>
// Import Custom Components
import homeNavbar from '../components/customer/Home-Navbar.vue';
import homeFooter from '../components/customer/Home-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Home',
    };
  },

  components: {
    'home-navbar': homeNavbar,
    'home-footer': homeFooter,
  },

  data() {
    return {};
  },
  mounted() {
    const advertisement = this.$route.query.advertisement;
    if (advertisement) {
      localStorage.setItem('_advertisement', advertisement);
		}
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: 'smooth' });
    },
  }
};
</script>

<style scoped>
.grey-wrapper {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.feature-box {
  font-size: 25px;
}

.feature-icon {
  margin-right: 0.5em;
  color: #00adee;
}

.blue-text {
  color: #154ea1;
}

.font-size-h3 {
  font-size: 130%;
}
#how-to-use {
  padding-top: 170px;
  padding-bottom: 60px;
}
@media only screen and (max-width: 991px) {
  #home {
    margin-bottom: 0;
  }
  #how-to-use {
    padding-top: 150px;
  }
}

#how-to-use .headline {
  font-size: 50px;
}

@media only screen and (max-width: 425px) {
  #how-to-use .headline {
    font-size: 30px;
  }
  .feature-box {
    font-size: 22px;
  }
}

#how-to-use .sub-headline {
  font-size: 20px;
}

@media only screen and (max-width: 425px) {
  #how-to-use .sub-headline {
    font-size: 12px;
  }
}

#how-to-use .btn-how-to-use {
  width: 75%;
  height: 64px;
  background: #00adee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
#how-to-use .text-getting-started {
  font-style: normal;
  letter-spacing: 0px;
  opacity: 1;
}

@media only screen and (max-width: 425px) {
  #how-to-use .btn {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  .font-size-h3 {
    font-size: 100%;
  }
}

#about-service {
  padding-top: 100px;
  padding-bottom: 100px;
}
#about-service .service-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #00000014;
  border-radius: 20px;
  opacity: 1;
  height: 100%;
  margin-bottom: 30px;
}
#our-characteristics {
  padding-top: 0px;
  padding-bottom: 60px;
}

#explain-how-use {
  padding-top: 100px;
  padding-bottom: 200px;
}
.description {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}
.new-account {
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-top: 0px;
  margin-bottom: 80px;
}
.account-button {
  background: #00adee;
  border: 1px solid #00adee;
  padding: 10px 10px;
  width: 300px;
  height: 64px;
  border-radius: 9px;
}
a.routerLink:hover {
  color: #000000;
}
.routerLink {
  text-decoration: none;
  color:#fff;
}
</style>
